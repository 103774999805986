<template>    
    <div class="optix-container" :class="{'optix-container-vertical':vertical}">
        <slot></slot>
    </div>
</template>
<script>
export default {
  name: "optix-container",
  props: {
      // Adds vertical paddings
      vertical: {
          type: Boolean,
          default: false
      }
  },
  computed: {},
  methods: {}
};
</script>
<style scoped>
div.optix-container {
    margin-left: 20px ;
    margin-right: 20px;
}
div.optix-container-vertical {
    padding-top: 20px ;
    padding-bottom: 20px;
}
</style>
