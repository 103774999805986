<template>
  <div class="max">
    <label class="subheader-text" v-if="label!=''">{{label}}</label>
    <div class="bordered">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "optix-sheet",
  props: {
    // Show label (like a html fieldset)
    label: {
      type: String,
      default: ''
    }
  }
};
</script>
<style scoped>
div.bordered {
  margin-bottom: 24px;
  background-color: #fff;
  min-width: 100%;
  border-radius: 0.625rem;
  -webkit-box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.04),
    0 4px 8px 0 rgba(20, 20, 20, 0.08);
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.04),
    0 4px 8px 0 rgba(20, 20, 20, 0.08);
}
div.max {
  width: 100%;
  max-width: 852px;
}
@media (min-width: 852px){
  div.max {
      width: 852px;
  }
}
.subheader-text {
  height: 20px;
  color: rgba(27, 37, 58, 0.54);
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 10px;
}
</style>