import { render, staticRenderFns } from "./OptixSheet.vue?vue&type=template&id=5bbb1b1e&scoped=true&"
import script from "./OptixSheet.vue?vue&type=script&lang=js&"
export * from "./OptixSheet.vue?vue&type=script&lang=js&"
import style0 from "./OptixSheet.vue?vue&type=style&index=0&id=5bbb1b1e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bbb1b1e",
  null
  
)

export default component.exports