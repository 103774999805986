<template>    
    <div class="optix-app" :class="classes">
        <v-app>
            <v-content>
                <slot></slot>
            </v-content>
        </v-app>
    </div>
</template>
<script>
export default {
  name: "optix-app",
  data() {
    return {
      device: 'web'
    }
  },
  computed: {
    classes(){
      return 'is-'+this.device
    }
  },
  methods: {},
  mounted(){  
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
        this.device = "windows";
    }else if (/android/i.test(userAgent)) {
        this.device =  "android";
    }else if(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.device =  "ios";
    }else{
      this.device = "web";
    }
  }
};
</script>
<style scoped>
</style>