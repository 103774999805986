import _ from 'lodash';
//import { forEach } from 'lodash';
import axios from 'axios';
import queryString from 'query-string';

/**
 * Handle all Optix configuration
 */
export default {
    Vue: false,
    args: {},
    install: function install(Vue, args) {

        this.Vue = Vue;
        this.args = args;

        Vue.prototype.$axios = axios;
        Vue.prototype.$_ = _;

        // Init promise, as single instance
        let initPromise = null;

        const events = {
            actionLinkClicked: 'optix.actionLinkClicked',
        };
        let listeners = {};

        function setEventListener(type, listener) {
            window.removeEventListener(type, listeners[type]);
            if (typeof listener === 'function') {
                listeners[type] = () => listener(); // hide "event" argument from user's listener
                window.addEventListener(type, listeners[type]);
            }
        }

        Vue.prototype.$optix = new Vue({
            data: {
                // Current environment
                environment: 'production',
                // Full URL 
                fullurl: '',
                // Query string arguments
                qs: {},
                // Hash string arguments
                hs: {},
                // URL detected access token
                access_token: '',
                // App client_id (required for canvases interactions)
                client_id: null,
                // Canvas identification
                identification: null,
                // Parameter used to detect the token
                access_token_argument: 'token',
            },
            methods: {
                // Init the app
                // Parse query, register environments, etc.
                // Can receive an opts object with extra parameters
                init(options) {
                    if (!initPromise) {
                        initPromise = new Promise((resolve, reject) => {

                            // Parse query string and base urls
                            this.fullurl = window.location.href;
                            this.qs = queryString.parse(window.location.search);
                            this.hs = queryString.parse(window.location.hash);


                            // May be provided by canvas
                            if(typeof this.qs['optix-environment'] === 'string' && this.qs['optix-environment'].length) {
                                this.environment = this.qs['optix-environment'];
                            }
                            if(typeof this.qs['optix-canvas-id'] === 'string' && this.qs['optix-canvas-id'].length) {
                                this.identification = this.qs['optix-canvas-id'];
                            }
                            if(typeof this.qs['optix-client-id'] === 'string' && this.qs['optix-client-id'].length) {
                                this.client_id = this.qs['optix-canvas-id'];
                            }

                            // Parameters set by the user
                            if (typeof options === 'object' && options !== null) {
                                // Allowed parameters
                                _.forEach(['client_id', 'identification', 'access_token_argument', 'environment'], (option) => {
                                    if (option in options) {
                                        this[option] = options[option];
                                    }
                                });
                            }

                            // Load access token
                            if (this.hs[this.access_token_argument]) {
                                this.access_token = this.hs[this.access_token_argument];
                            } else if (this.qs[this.access_token_argument]) {
                                this.access_token = this.qs[this.access_token_argument];
                            }

                            // Browser commands
                            try {
                                // Show main app (if using optix-show-on-load class)
                                let elements = window.document.querySelectorAll('.optix-show-on-load');
                                _.forEach(elements, (element) => {
                                    element.className = element.className.replace(/\boptix-show-on-load\b/g, "");
                                });
                            } catch (e) {
                                // Not working as regular app on browser
                                reject();
                            }
                            resolve();
                        });
                    }
                    return initPromise;
                },
                // Send command to the canvas host // TODO
                command(command, context) {
                    var iOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false);
                    if (iOS) {
                        document.documentElement.className += ' is-ios';
                    }
                    var android = (navigator.userAgent.match(/(Android)/g) ? true : false);
                    if (android) {
                        document.documentElement.className += ' is-android';
                    }
                    let message = {
                        command: command,
                        payload: {
                            client_id: this.client_id
                        }
                    };
                    if (typeof context === 'object' && context !== null) {
                        Object.assign(message.payload, context);
                    }
                    let dispatch = 'Web';
                    if (iOS && typeof webkit !== "undefined") {
                        dispatch = 'iOS';
                        // eslint-disable-next-line no-undef
                        webkit.messageHandlers.canvasCommand.postMessage(JSON.stringify(message));
                    } else if (typeof Android !== "undefined") {
                        dispatch = 'Android';
                        // eslint-disable-next-line no-undef
                        Android.canvasCommand(JSON.stringify(message));
                    } else {
                        window.parent.postMessage(message, "*");
                    }
                    if(this.qs.optixDebug){
                        // eslint-disable-next-line no-console
                        console.log('command '+command, dispatch , message);
                    }
                },
                // GraphQL
                graphQL(query, variables) {
                    let url;
                    if (this.environment == 'local') {
                        url = 'http://api.sharedesk.local/graphql';
                    } else if (this.environment == 'staging') {
                        url = 'https://api.catalufa.net/graphql';
                    } else {
                        url = 'https://api.optixapp.com/graphql';
                    }
                    return axios.post(url, {
                        query: query,
                        variables: variables,
                        access_token: this.access_token
                    });
                },
                // Download paginated query
                downloadPaginatedQuery(graphQuery, filters, elementsPerPage) {
                    let page = 1;
                    let total = 0;
                    let results = [];
                    let elementsPerPageConfiguration = elementsPerPage || 500;
                    let promise = new Promise((resolve, reject) => {
                        let downloadNextPage = () => {
                            let params = filters;
                            params['page'] = page;
                            params['limit'] = elementsPerPageConfiguration;
                            // locations: (this.checkins.filters.locations.length ? this.checkins.filters.locations : null)
                            this.graphQL(graphQuery, params).then((result) => {
                                let current = results.length;
                                total = result.data.data.collection.total;
                                results = results.concat(result.data.data.collection.data);
                                if (current == results.length || results.length == total) {
                                    resolve({
                                        total: total,
                                        results: results
                                    });
                                } else {
                                    let estimatedPages = 1 + Math.floor(total / elementsPerPageConfiguration);
                                    page++;
                                    this.generatingPct = 10 + (page / estimatedPages * 80);
                                    this.generatingStatus = Math.round(this.generatingPct) + '%';
                                    if (page < 50) { // HARDCODED! No more than 50 requests
                                        downloadNextPage();
                                    } else {
                                        reject('error loading');
                                    }
                                }
                            }).catch(e => {
                                reject(e);
                            });
                        };
                        downloadNextPage();
                    });
                    return promise;
                },
                // Opens a modal
                openModal(identifier, context) {
                    this.command('openModal', {
                        identifier,
                        context
                    });
                },
                // Resize the canvas size
                resize(height, width) {
                    this.command('resize', {
                        height,
                        width
                    });
                },
                /**
                 * Show the button at the top right corner
                 * @param {Object} [options]
                 * @param {String} options.text
                 * @param {Boolean} [options.disabled]
                 * @param {Function} [listener]
                 */
                setActionLink(options, listener) {
                    options = options || {};
                    options.text = options.text || '';
                    options.disabled = !!options.disabled;

                    this.command('setActionLink', options);

                    setEventListener(events.actionLinkClicked, listener);
                },
                removeActionLink() {
                    this.setActionLink();
                },
                // Instruct the browser to apply or not shadow to the canvas
                canvasShadow(active){
                    this.command('canvasShadow', {disabled: !active});
                },
                // Close parent modal
                closeParentModal() {
                    this.command('canvasModalClose');
                },
                // Ask mobile app to hide native loader
                canvasFinishLoading() {
                    this.command('canvasFinishLoading');
                }
            }
        });
    },
    version: '0.1'
};
